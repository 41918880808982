// Framework
import { Injectable } from "@angular/core";
// Plugins
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class AlertService {

    constructor(private toastr: ToastrService, private translate: TranslateService, ) { }

    success(message: string): void {
        this.toastr.success(this.translate.instant(message));
    }

    error(message: string): void {
        this.toastr.error(this.translate.instant(message));
    }

    info(message: string): void {
        this.toastr.info(this.translate.instant(message));
    }

    warn(message: string): void {
        this.toastr.warning(this.translate.instant(message));
    }

    clear(): void {
        this.toastr.clear();
    }
}
